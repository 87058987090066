import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ToasterPosition } from '../../enums/toaster-position';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastr: ToastrService) {}

  success(title: string, message: string): void {
    this.toastr.success(message, title, {
      positionClass: ToasterPosition.bottomRight,
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,
    });
  }
}
