<!-- Container-fluid starts -->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>{{ title }}</h5>
        </div>
        <div class="card-body custom-datatable noscroll">
          <div class="custom-datatable">
            <table class="table">
              <thead>
                <tr>
                  <th
                    *ngFor="let header of headers"
                    scope="col"
                    sortable="header.sortable"
                    (sort)="onSort($event)"
                  >
                    {{ header.title }}
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of data">
                  <td scope="row" *ngFor="let header of headers">
                    {{ getValue(item, header) }}
                  </td>
                  <td>
                    <button (click)="onSelect(item.name)">
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-between p-2">
              <ngb-pagination
                [collectionSize]="(total$ | async)!"
                [(page)]="service.page"
                [pageSize]="service.pageSize"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends -->
