import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContentLayoutComponent } from "./shared/components/layout/content-layout/content-layout.component";
import { ManageStaffComponent } from "./components/proseeker/staff/manage-staff/manage-staff.component";
import { ASC } from "./config/navigation.constants";
import { DashboardModule } from "./components/dashboard/dashboard.module";

const routes: Routes = [
  {
    path: "",
    component: ContentLayoutComponent,
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./components/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "manage/staff",
        component: ManageStaffComponent,
        data: {
          title: "Manage Staff",
          defaultSort: "id," + ASC,
        },
      },
    ],
  },

  {
    path: "**",
    redirectTo: "dashboard/admin",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
