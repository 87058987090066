import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConfigService } from '../../../core/config/application-config.service';
import {
  CreateStaffUserRequestModel,
  IStaffUserResponseModel,
} from '../../../shared/model/proseeker/staff.model';

import { createRequestOption } from '../../../core/request/request-util';

export type HttpRespnseType = HttpResponse<IStaffUserResponseModel>;
export type HttpArrayRespnseType = HttpResponse<IStaffUserResponseModel[]>;

@Injectable({
  providedIn: 'root',
})
export class StaffService {
  constructor(
    protected http: HttpClient,
    protected applicationConfigService: ApplicationConfigService
  ) {}

  protected resourceUrl =
    this.applicationConfigService.getUserServiceEndpoint('api/staffs');

  create(request: CreateStaffUserRequestModel): Observable<HttpRespnseType> {
    return this.http.post<IStaffUserResponseModel>(this.resourceUrl, request, {
      observe: 'response',
    });
  }

  query(req?: any): Observable<HttpArrayRespnseType> {
    const options = createRequestOption(req);
    return this.http.get<IStaffUserResponseModel[]>(this.resourceUrl, {
      params: options,
      observe: 'response',
    });
    // .pipe(map((res) => this.convertResponseArrayFromServer(res)));
  }

  delete(id: number) {
    return this.http.delete(`${this.resourceUrl}/${id}`, {
      observe: 'response',
    });
  }

  // protected convertResponseArrayFromServer(res: HttpResponse<IStaffUserResponseModel[]>): HttpResponse<IStaffUserResponseModel[]> {
  //   return res.clone({
  //     body: res.body ? res.body.map(item => this.convertDateFromServer(item)) : null,
  //   });
  // }

  // protected convertDateFromServer(res: IStaffUserResponseModel): IStaffUserResponseModel {
  //   return {
  //     ...res,
  //     userDetail res.userDetail.dateOfBirth ? dayjs(res.userDetail.dateOfBirth) : undefined,
  //   };
  // }
}
