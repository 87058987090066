import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Data, ParamMap, Router } from "@angular/router";
import { Observable, combineLatest } from "rxjs";
import { filter, switchMap, tap } from "rxjs/operators";
import { IStaffUserResponseModel } from "src/app/shared/model/proseeker/staff.model";
import { HttpArrayRespnseType, StaffService } from "../staff.service";
import {
  ITEMS_PER_PAGE,
  PAGE_HEADER,
  TOTAL_COUNT_RESPONSE_HEADER,
} from "../../../../config/pagination.constants";
import {
  ASC,
  DESC,
  SORT,
  DEFAULT_SORT_DATA,
  ITEM_DELETED_EVENT,
} from "../../../../config/navigation.constants";
import { HttpHeaders } from "@angular/common/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeleteStaffComponent } from "../delete-staff/delete-staff.component";
import { ToastService } from "src/app/shared/components/toast/toast.service";

@Component({
  selector: "app-list-staff",
  templateUrl: "./list-staff.component.html",
  styleUrls: ["./list-staff.component.scss"],
})
export class ListStaffComponent implements OnInit {
  staffMembers?: IStaffUserResponseModel[];
  isLoading = false;
  staffData: any;
  tableHeader = [
    {
      sortable: "userDetail.firstName",
      title: "First Name",
      nested: true,
    },
    {
      sortable: "userDetail.lastName",
      title: "Last Name",
      nested: true,
      lookup: null,
    },
    {
      sortable: "userDetail.username",
      title: "User Name",
      nested: true,
      lookup: null,
    },
    {
      sortable: "userDetail.userType",
      title: "User Type",
      nested: true,
      lookup: null,
    },
    {
      sortable: "userDetail.profileStatus",
      title: "Profile Status",
      nested: true,
      lookup: null,
    },
    {
      sortable: "userDetail.gender",
      title: "Gender",
      nested: true,
      lookup: null,
    },
    {
      sortable: "userDetail.dateOfBirth",
      title: "Date of Birth",
      nested: true,
      lookup: null,
    },
    {
      sortable: "staffType",
      title: "Staff Type",
      lookup: "StaffType",
      nested: false,
    },
  ];
  title = "Staff Management";

  predicate = "id";
  ascending = true;

  itemsPerPage = ITEMS_PER_PAGE;
  totalItems = 0;
  page = 1;

  constructor(
    private staffService: StaffService,
    protected activatedRoute: ActivatedRoute,
    public router: Router,
    protected modalService: NgbModal,
    private toastrService: ToastService
  ) {}

  ngOnInit() {
    this.load();
  }

  load(): void {
    this.loadFromBackendWithRouteInformations().subscribe({
      next: (res: HttpArrayRespnseType) => {
        this.onResponseSuccess(res);
      },
    });
  }

  protected onResponseSuccess(response: HttpArrayRespnseType): void {
    this.fillComponentAttributesFromResponseHeader(response.headers);
    const dataFromBody = this.fillComponentAttributesFromResponseBody(
      response.body
    );
    this.staffMembers = dataFromBody;
    this.staffData = dataFromBody;
  }

  protected fillComponentAttributesFromResponseHeader(
    headers: HttpHeaders
  ): void {
    this.totalItems = Number(headers.get(TOTAL_COUNT_RESPONSE_HEADER));
  }

  protected fillComponentAttributesFromResponseBody(
    data: IStaffUserResponseModel[] | null
  ): IStaffUserResponseModel[] {
    return data ?? [];
  }

  protected loadFromBackendWithRouteInformations(): Observable<HttpArrayRespnseType> {

    return combineLatest([
      this.activatedRoute.queryParamMap,
      this.activatedRoute.data,
    ]).pipe(
      tap(([params, data]) =>
        this.fillComponentAttributeFromRoute(params, data)
      ),
      switchMap(() =>
        this.queryBackend(this.page, this.predicate, this.ascending)
      )
    );
  }

  protected fillComponentAttributeFromRoute(
    params: ParamMap,
    data: Data
  ): void {
    const page = params.get(PAGE_HEADER);
    this.page = +(page ?? 1);
    const sort = (params.get(SORT) ?? data[DEFAULT_SORT_DATA]).split(",");
    this.predicate = sort[0];
    this.ascending = sort[1] === ASC;
  }

  protected queryBackend(
    page?: number,
    predicate?: string,
    ascending?: boolean
  ): Observable<HttpArrayRespnseType> {
    this.isLoading = true;
    const pageToLoad: number = page ?? 1;
    const queryObject = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      eagerload: true,
      sort: this.getSortQueryParam(predicate, ascending),
    };
    return this.staffService
      .query(queryObject)
      .pipe(tap(() => (this.isLoading = false)));
  }

  protected getSortQueryParam(
    predicate = this.predicate,
    ascending = this.ascending
  ): string[] {
    const ascendingQueryParam = ascending ? ASC : DESC;
    if (predicate === "") {
      return [];
    } else {
      return [predicate + "," + ascendingQueryParam];
    }
  }

  delete(staffMember: IStaffUserResponseModel): void {
    const modalRef = this.modalService.open(DeleteStaffComponent, {
      size: "lg",
      backdrop: "static",
    });
    modalRef.componentInstance.staffMember = staffMember;
    modalRef.closed
      .pipe(
        filter((reason) => reason === ITEM_DELETED_EVENT),
        switchMap(() => this.loadFromBackendWithRouteInformations())
      )
      .subscribe({
        next: (res: HttpArrayRespnseType) => {
          this.onResponseSuccess(res);
          this.toastrService.success(
            "Staff member with ID {" +
              staffMember.id +
              "} is deleted successfully.",
            ""
          );
        },
      });
  }

  edit(): void {}

  navigateToWithComponentValues(): void {
    this.handleNavigation(this.page, this.predicate, this.ascending);
  }

  protected handleNavigation(
    page = this.page,
    predicate?: string,
    ascending?: boolean
  ): void {
    const queryParamsObj = {
      page,
      size: this.itemsPerPage,
      sort: this.getSortQueryParam(predicate, ascending),
    };

    this.router.navigate(["./"], {
      relativeTo: this.activatedRoute,
      queryParams: queryParamsObj,
    });
  }

  navigateToPage(page = this.page): void {
    this.handleNavigation(page, this.predicate, this.ascending);
  }
}
