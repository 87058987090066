export enum Lookup_UserType {
  STAFF = '3',
}

export enum Lookup_ProfileStatus {
  PENDING_APPROVAL = '0',
  ACTIVE = '1',
  BLOCKED = '2',
  INACTIVE = '3',
  DELECTED = '4',
}
