<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-xl-12">
      <jhi-alert-error></jhi-alert-error>
      <div class="row">
        <div class="col-sm-12">
          <app-list-staff></app-list-staff>
        </div>
        <div class="card-footer">
        </div>
      </div>
    </div>
  </div>
</div>