
<div class="modal-header">
  <h4 class="modal-title" data-cy="ultrasoundTherapyDeleteDialogHeading">Confirm delete operation</h4>

  <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="cancel()">&times;</button>
</div>

<div class="modal-body">
  <jhi-alert-error></jhi-alert-error>
  <p id="jhi-delete-ultrasoundTherapy-heading">Are you sure you want to delete Staff member {{
    staffMember.id }}?</p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="cancel()">
    <fa-icon icon="ban"></fa-icon>&nbsp;<span>Cancel</span>
  </button>

  <button type="button" (click)="confirmDelete(staffMember.id)" class="btn btn-danger">
    <fa-icon icon="times"></fa-icon>&nbsp;<span>Delete</span>
  </button>
</div>