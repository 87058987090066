import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateStaffFormComponent } from '../create-staff-form/create-staff-form.component';

@Component({
  selector: 'app-create-staff-button',
  templateUrl: './create-staff-button.component.html',
  styleUrls: ['./create-staff-button.component.scss'],
})
export class CreateStaffButtonComponent implements OnInit {
  constructor(protected modalService: NgbModal) {}

  ngOnInit(): void {}

  createStaffFormModal() {
    this.modalService.open(CreateStaffFormComponent, {
      size: 'lg',
      backdrop: 'static',
    });
  }
}
