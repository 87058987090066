import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ITEM_DELETED_EVENT } from 'src/app/config/navigation.constants';
import { IStaffUserResponseModel } from 'src/app/shared/model/proseeker/staff.model';
import { StaffService } from '../staff.service';

@Component({
  selector: 'app-delete-staff',
  templateUrl: './delete-staff.component.html',
  styleUrls: ['./delete-staff.component.scss'],
})
export class DeleteStaffComponent {
  staffMember: IStaffUserResponseModel;

  constructor(
    protected staffService: StaffService,
    protected activeModal: NgbActiveModal
  ) {}

  cancel(): void {
    this.activeModal.dismiss();
  }

  confirmDelete(id: number): void {
    this.staffService.delete(id).subscribe(() => {
      this.activeModal.close(ITEM_DELETED_EVENT);
    });
  }
}
