import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IUserDetailModel } from 'src/app/shared/model/proseeker/user-detail.model';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { ICreateStaffUserRequestModel } from '../../../../shared/model/proseeker/staff.model';
import { HttpRespnseType, StaffService } from '../staff.service';
import { ToastService } from '../../../../shared/components/toast/toast.service';
import {
  Lookup_UserType,
  Lookup_ProfileStatus,
} from '../../../../shared/enums/Lookups';

@Component({
  selector: 'app-create-staff-form',
  templateUrl: './create-staff-form.component.html',
  styleUrls: ['./create-staff-form.component.scss'],
})
export class CreateStaffFormComponent implements OnInit {
  createStaffForm: FormGroup;

  constructor(
    protected activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private staffService: StaffService,
    private toastrService: ToastService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.createStaffForm = this.fb.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$'),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$'),
        ],
      ],
      // email: ['', [Validators.email]],
      username: ['', []],
      password: ['', Validators.required],
      gender: ['', Validators.required],
      staffType: ['', Validators.required],
    });
  }

  cancel(): void {
    this.activeModal.close();
  }

  submit() {
    if (this.createStaffForm.invalid) {
      return this.createStaffForm.markAllAsTouched();
    }

    var userDetails: IUserDetailModel = {
      firstName: this.createStaffForm.controls['firstName'].getRawValue(),
      lastName: this.createStaffForm.controls['lastName'].getRawValue(),
      gender: this.createStaffForm.controls['gender'].getRawValue(),
      username: this.createStaffForm.controls['username'].getRawValue(),
      userType: Lookup_UserType.STAFF,
      profileStatus: Lookup_ProfileStatus.ACTIVE,
    };

    var createRequest: ICreateStaffUserRequestModel = {
      userDetail: userDetails,
      password: this.createStaffForm.controls['password'].getRawValue(),
      staffType: this.createStaffForm.controls['staffType'].getRawValue(),
    };

    this.subscribeToSaveResponse(this.staffService.create(createRequest));

    // this.activeModal.close();
  }

  subscribeToSaveResponse(result: Observable<HttpRespnseType>): void {
    result.subscribe({
      next: () => this.onSaveSuccess(),
      error: () => this.onSaveError(),
    });
  }

  protected onSaveSuccess(): void {
    this.activeModal.close();
    this.toastrService.success('', 'Staff member added successfully');
  }

  protected onSaveError(): void {
    // Api for inheritance.
  }
}
