<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-xl-12">
      <jhi-alert-error></jhi-alert-error>
      <div class="row">
        <div class="col-sm-12">
          <form class="theme-form" novalidate [formGroup]="createStaffForm" (ngSubmit)="submit()">
            <div class="card">
              <div class="card-header">
                <h5>Add new staff member</h5>
              </div>
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Title</label>
                  <div class="col-sm-9">
                    <input class="form-control" type="text">
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">First Name</label>
                  <div class="col-sm-9">
                    <input class="form-control" type="text" [formControl]="createStaffForm.controls['firstName']"
                      required>

                    <div
                      *ngIf="createStaffForm.controls.firstName.touched && createStaffForm.controls.firstName.errors?.required"
                      class="text text-danger">
                      First Name is required.
                    </div>
                    <div
                      *ngIf="createStaffForm.controls.firstName.touched && createStaffForm.controls.firstName.errors?.pattern"
                      class="text text-danger">
                      First Name must be an alphabates.
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Last Name</label>
                  <div class="col-sm-9">
                    <input class="form-control" type="text" [formControl]="createStaffForm.controls['lastName']"
                      required>

                    <div
                      *ngIf="createStaffForm.controls.lastName.touched && createStaffForm.controls.lastName.errors?.required"
                      class="text text-danger">
                      Last Name is required.
                    </div>
                    <div
                      *ngIf="createStaffForm.controls.lastName.touched && createStaffForm.controls.lastName.errors?.pattern"
                      class="text text-danger">
                      Last Name must be an alphabates.
                    </div>
                  </div>
                </div>

                <fieldset class="form-group">
                  <div class="row">
                    <label class="col-form-label col-sm-3 pt-0">Gender</label>
                    <div class="col-sm-9">
                      <div class="radio radio-primary ms-2">
                        <input id="gender_male" type="radio" name="gender" value="0"
                          [formControl]="createStaffForm.controls['gender']">
                        <label for="gender_male" class="m-b-10">Male</label>
                      </div>
                      <div class="radio radio-primary ms-2">
                        <input id="gender_female" type="radio" name="gender" value="1"
                          [formControl]="createStaffForm.controls['gender']">
                        <label for="gender_female" class="m-b-10">Female</label>
                      </div>
                      <div class="radio radio-primary ms-2">
                        <input id="gender_other" type="radio" name="gender" value="2" checked=""
                          [formControl]="createStaffForm.controls['gender']">
                        <label for="gender_other" class="m-b-10">Other</label>
                      </div>
                      <div
                        *ngIf="createStaffForm.controls.gender.touched && createStaffForm.controls.gender.errors?.required"
                        class="text text-danger">
                        Gender is required.
                      </div>
                    </div>
                  </div>
                </fieldset>

                <fieldset class="form-group">
                  <div class="row">
                    <label class="col-form-label col-sm-3 pt-0">Staff Member Type</label>
                    <div class="col-sm-9">
                      <div class="radio radio-primary ms-2">
                        <input id="staff_type_fi" type="radio" name="staff_type" value="0"
                          [formControl]="createStaffForm.controls['staffType']">
                        <label for="staff_type_fi" class="m-b-10">Finance Manager</label>
                      </div>
                      <div class="radio radio-primary ms-2">
                        <input id="staff_type_su" type="radio" name="staff_type" value="1"
                          [formControl]="createStaffForm.controls['staffType']">
                        <label for="staff_type_su" class="m-b-10">Support Manager</label>
                      </div>
                      <div
                        *ngIf="createStaffForm.controls.staffType.touched && createStaffForm.controls.staffType.errors?.required"
                        class="text text-danger">
                        Staff type is required.
                      </div>
                    </div>
                  </div>
                </fieldset>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Username</label>
                  <div class="col-sm-9">
                    <input class="form-control" type="text" [formControl]="createStaffForm.controls['username']">
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Password</label>
                  <div class="col-sm-9">
                    <input class="form-control" type="password" [formControl]="createStaffForm.controls['password']">

                    <div
                      *ngIf="createStaffForm.controls.password.touched && createStaffForm.controls.password.errors?.required"
                      class="text text-danger">
                      Password is required.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button class="btn btn-primary me-1" type="submit">Submit</button>
                <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->